<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <!--  -->
          <base-material-card
            title="Create Poll"
            icon="mdi-poll"
          >
            <!--  -->
            <v-form @submit.prevent="sendPolling">
              <v-card>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="poling.data.question"
                      label="Pertanyaan"
                      placeholder="Masukan Pertanyaan"
                    />
                  </v-list-item-content>
                </v-list-item>

                <div
                  v-for="(data, i) in poling.data.options"
                  :key="i"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <span
                        class="d-flex justify-end"
                        style="cursor: pointer;"
                        @click="deleteInput(i)"
                      ><v-icon color="red">
                        mdi-close-octagon
                      </v-icon></span>
                      <v-text-field
                        v-model="data.option"
                        label="Pilihan"
                        :placeholder="'Masukan Pilihan ke-' + (i + 1)"
                        type="text"
                        @keyup="createNewInput(i)"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div>
                  <v-list-item>
                    <v-list-item-content>
                      <!--  -->
                      <v-checkbox
                        v-model="poling.data.settings.anonymous_answer"
                        label="Anonymous Answer"
                      />
                    </v-list-item-content>
                    <v-list-item-content
                      class="ml-12 d-flex justify-end mt-6 mb-5 mr-6"
                    >
                      <!--  -->
                      <v-checkbox
                        v-model="poling.data.settings.multiple_answer"
                        label="Multiple Answer"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-card>
              <div class="d-flex justify-end mt-6 mb-5 mr-6">
                <v-btn
                  color="primary"
                  @click="sendPolling"
                >
                  <span class="mr-1">
                    <v-icon light>mdi-pencil-plus</v-icon>
                  </span>
                  Buat
                </v-btn>
              </div>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'CreatePolling',
    data () {
      return {
        poling: {
          meta: {},
          data: {
            quenstion: '',
            settings: {
              anonymous_answer: 0,
              multiple_answer: 0,
              quiz_mode: 0,
            },
            options: [
              {
                option: '',
              },
            ],
          },
        },
      }
    },
    mounted () {},
    methods: {
      createNewInput (index) {
        console.log(this.poling.data.options.length)
        if (index > 0 && this.poling.data.options.length > 1) {
          if (this.poling.data.options.length === index + 1) {
            this.poling.data.options.push({ option: '' })
            console.log(this.poling.data.options)
          } else if (
            this.poling.data.options[index + 1].option !== '' &&
            this.poling.data.options.length === index + 1
          ) {
            this.poling.data.options.push({ option: '' })
          }
        } else if (index >= 0 && this.poling.data.options.length === 1) {
          this.poling.data.options.push({ option: '' })
        }
      },
      theFirst () {
        if (this.poling.data.options.length === 0) {
          this.poling.data.options.push({ option: '' })
        }
      },
      deleteInput (index) {
        if (index > 0 || this.poling.data.options.length > 1) {
          this.poling.data.options.splice(index, 1)
        }
      },
      sendPolling () {
        axios
          .post('/v1/polling', {
            question: this.poling.data.question,
            settings: {
              anonymous_answer: this.poling.data.settings.anonymous_answer || 0,
              multiple_answer: this.poling.data.settings.multiple_answer || 0,
              quiz_mode: 0,
            },
            options: this.poling.data.options,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/polling' })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          })
      },
    },
  }
</script>

<style></style>
